export const resources = {
    en: {
      translation: {
        blocks: {
            modalWindow: {
                title: 'Frequently Asked Questions'
            },
            toaster: {
                success: 'Message sent',
                error: 'Error sending message'
            },
            mainHero:{
                heroTitle: 'Innovative solutions to protect the future',
                heroSubtitle: 'We strive to become one of the world leaders in the development and production of high-tech products in the field of defense and security'
            },
            advantages:{
                title: 'Our advantages',
                text: 'To provide reliable protection against unmanned aerial vehicles, we have developed a number of advanced technologies. Our solutions combine the latest developments in the field of radar, electronic jamming and artificial intelligence.',
                advantagesOne: 'High efficiency',
                advantagesTwo: 'Reliability and support',
                advantagesThree: 'Flexibility and adaptability',
                advantagesFour: 'Modern technologies',
                advantagesFive: 'Extensive experience and professionalism',
                advantagesSix: 'Wide range of applications',
            },
            mainAbout: {
                mainAboutLeft: 'We are pleased to provide you with our company specialized in the development of high-performance systems countering small-sized UAVs.',
                mainAboutRight: 'We have achieved the trust and support of local users as well as ministries and departments, including law enforcement agencies of the Republic of Ushbekistan'
            },
            news: {
                title: 'News',
                seeMore: "See more"
            },
            navigation: {
                main: 'Home',
                about: 'About us',
                products: 'Products',
                news: 'News',
                contacts: 'Contacts',
            },
            buttons:{
                additionalInfo: 'Additional information',
                contactUs: 'Contact us',
                anotherNews: 'Read other news'
            },
            contactUs:{
                title:'Contact us!',
                subtitle: 'And our employees will definitely contact you.',
                name: 'Name',
                company: 'Company',
                email: 'Email',
                city: 'City',
                text:'Message',
                submit: 'Submit',
                checkboxText: 'I agree to receive news about the latest innovations from ELECTRONIC AUTONOMOUS SOLUTIONS'
            },
            footer: {
                privacyPolicy: 'Privacy Policy',
                licenseAgreement: 'License Agreement',
                frequentlyAskedQuestions: 'Frequently Asked Questions',
                contactsUs: 'Contact us',
                        rights: 'all rights reserved'
            },
            product:{
                title: 'Our products',
                subtitle: 'We are pleased to provide you with a range of our products.',
                contentTitle: 'Our products',
                seeAll: 'See all'
            },
            pageAbout:{
                aboutHero: {
                    title: 'About Us',
                    subtitle: 'We are pleased to provide you with our company specialized in the development of high-performance systems countering small-sized UAVs.',
                },
                aboutSecondBlock: {
                    leftText: 'Currently, and in order to supply reliable systems for countering small-sized UAVs for our customers, we are working to improve our products',
                    rightText: 'We are ready to provide you with products in accordance with your requirements and have every opportunity modernize, redesign, provide technical support and service for stable operation of this product',
                },
                certificates: {
                    title: 'Corporate awards and certificates',
                    subtitle: 'Electronic Autonomous Solutions is proud of the corporate awards it has received and certificates that confirm our achievements and high level of quality. We were honored prestigious awards for innovation in the field of anti-drone technologies and the successful implementation of advanced decisions.'
                },
                international: {
                    title: 'International experience',
                    subtitle: 'Our international experience includes several key points. We began to actively enter foreign markets in 2023. Our anti-drone systems were successfully presented at international exhibitions in Europe and Asia, where they received positive feedback from professionals and customers.',
                    one: {
                        first: {
                            title: 'DEX-2023',
                            subtitle: 'DEX-2023 - 16th International Exhibition of Defense and Security Industry, held from 20 to 24 February 2023 in Abu Dhabi (United Arab Emirates)'
                        },
                        second: {
                            subtitle: 'DEX-2023 - 16th International Exhibition of Defense and Security Industry, held from 20 to 24 February 2023 in Abu Dhabi (United Arab Emirates)'
                        }
                    },
                    two: {
                        first: {
                            title: 'Inter Drone Expo',
                            subtitle: 'The exhibition took place from 15 to 17 June 2023 in Sofia, Bulgaria.'
                        },
                        second: {
                            subtitle: 'The exhibition took place from 15 to 17 June 2023 in Sofia, Bulgaria.'
                        }
                    },
                    three: {
                        first: {
                            title: 'IDEF 2023',
                            subtitle: 'International Defense Industry Exhibition, held from July 25 to 28, 2023 in Istanbul, Türkiye.'
                        },
                        second: {
                            subtitle: 'International Defense Industry Exhibition, held from July 25 to 28, 2023 in Istanbul, Türkiye.'
                        }
                    },
                    four: {
                        first: {
                            title: 'SEDEC 2024',
                            subtitle: 'International Defense Security Exhibition held from 21 to 23 May 2024 in Ankara, TürkiyeInternational military-technical forum, held from 14 to 20 August 2023 in Kubinka, Russian Federation'
                        },
                        second: {
                            subtitle: 'International Defense Security Exhibition held from 21 to 23 May 2024 in Ankara, TürkiyeInternational military-technical forum, held from 14 to 20 August 2023 in Kubinka, Russian Federation'
                        }
                    },
                    five: {
                        first: {
                            title: 'Армия -2023',
                            subtitle: 'International military-technical forum, held from 14 to 20 August 2023 in Kubinka, Russian Federation'
                        },
                        second: {
                            subtitle: 'International military-technical forum, held from 14 to 20 August 2023 in Kubinka, Russian Federation'
                        }
                    },
                    six: {
                        first: {
                            title: 'World Defense Show 2024',
                            subtitle: 'International Defense Industry Exhibition, held from 4 to 8 February 2024 in Riyadh, Saudi Arabia'
                        },
                        second: {
                            subtitle: 'International Defense Industry Exhibition, held from 4 to 8 February 2024 in Riyadh, Saudi Arabia'
                        }
                    },
                    seven: {
                        first: {
                            title: 'Eurosatory 2024',
                            subtitle: 'International Defense Exhibition, which took place from 17 to 21 June 2024 in Paris, France'
                        },
                        second: {
                            subtitle: 'International Defense Exhibition, which took place from 17 to 21 June 2024 in Paris, France'
                        }
                    }
                },
                development:{
                    title: 'Our development',
                    one: {
                        textOne: 'Founding of the company “Electronic autonomous solutions” LLC',
                        textTwo: 'Development of a test (prototype) anti-drone device.',
                        textThree: 'Conducting the first practical test of our company’s portable anti-drone device for the Ministry of Defense, Ministry of Internal Affairs. State Security Service of the President of the Republic of Uzbekistan, Border Troops of the State Security Service, National Guard and other interested organizations.',
                    },
                    two: {
                        textOne: 'Obtaining a license from the Defense Industry Agency under the Ministry of Defense of the Republic of Uzbekistan for the development and implementation of portable and stationary systems for countering small-sized UAVs.',
                        textTwo: 'Scientific research and development of portable and stationary systems to counter small UAVs.',
                        textThree: 'Conducting practical testing of portable and stationary systems for countering small-sized UAVs for the Ministry of Defense of the Ministry of Internal Affairs. State Security Service of the President of the Republic of Uzbekistan, Border Troops of the State Security Service, National Guard and other interested organizations.',
                        textFour: 'Obtaining an international certificate ISO 9001:2015 (quality management system) in the field: production of anti-drone kits <<EAS>> (anti-drone).',
                        textFive: 'Conducting meetings with foreign companies on cooperation and joint activities (with the company <<Gurbuz Trade>> (Turkey) signing the document "Agreement on Cooperation and Joint Activities >>; with JSC "West Kazakhstan Machine-Building Plant>>. The Republic of Kazakhstan was concluded bilateral memorandum on <<Cooperation and joint activities>>)'
                    },
                    three: {
                        textOne: 'Inclusion of the company <<EAS>> in the State Register of the <<Single Supplier>> of the Republic of Uzbekistan',
                        textTwo: 'Scientific research and development of new high-tech portable, mobile and stationary systems for countering small-sized UAVs.',
                        textThree: 'Confirmation of the international certificate ISO 9001 2015 (quality management system) in the field of production of anti-drone complexes <<EAS>> (ftidron)',
                        textFour: 'Conclusion of a contract for the supply of systems to counter small UAVs for the needs of the Ministry of Internal Affairs. Border troops of the State Security Service and the National Guard of the Republic of Uzbekistan',
                        textFive: 'Проведение встреч  с зарубежными  компаниями  о сотрудничестве и совместной  деятельности ( компания  <<Skycope>>  (Канада)  подписание документа <<Соглашение о сотрудничестве  и совместной деятельности >>;  Оборонная промышленность (Завол артиллерийского оборудования) Республики Индии - заключен двусторонний меморандум о <<Сотрудничестве и совместной деятельности>>)'
                    },
                    four: {
                        textOne: 'Development of the company <<Electronic automous solutions>> to the international level.',
                        textTwo: 'Scientific research and development of new high-tech systems and complexes (electronic warfare, air defense, unmanned aerial systems, etc.).',
                        textThree: 'Production and sale of advanced complex systems (electronic warfare, air defense, unmanned aerial systems, work equipment, etc.) based on artificial intelligence in the field of defense and security of the Republic of Uzbekistan.',
                        textFour: 'Export of production of the company <<EAS>> to foreign countries. Establishing bilateral international cooperation and joint activities.',
                    },
                }
            }
        },
      },
    },
    ru: {
      translation: {
        blocks: {
            modalWindow: {
                title: 'Часто задаваемые вопросы'
            },
            toaster: {
                success: 'Сообщение отправленно',
                error: 'Ошибка отправки сообщения'
            },
            mainHero:{
                heroTitle: 'Иновационные решения для защиты будущего',
                heroSubtitle: 'Мы стремимся стать одним из мировых лидеров в области разработки и производства высокотехнологичной продукции в сфере обороны и безопасности'
            },
            mainAbout: {
                mainAboutLeft: 'Мы рады предоставить вам нашу компанию, специализирующуюся в разработке высокоэффективных систем противодействия малоразмерным БПЛА.',
                mainAboutRight: 'Мы достигли доверия и поддержки местных пользователей, а также министерств и ведомства, в том числе и силовых структур Руспублики Ущбекистан'
            },
            news: {
                title: 'Новости',
                seeMore: "Подробнее"
            },
            advantages:{
                title: 'Наши преимущества',
                text: ' Для обеспечения надежной защиты от беспилотных летательных аппаратов, мы разработали ряд передовых технологий. Наши решения обьединяют самые современные разработки в области радиолокации, радиоэлектронного подавления и искуственного интелекта.',
                advantagesOne: 'Высокая эффективность',
                advantagesTwo: 'Надежность и поддержка',
                advantagesThree: 'Гибкость и адаптивность',
                advantagesFour: 'Современные технологии',
                advantagesFive: 'Обширный опыт и профессионализм',
                advantagesSix: 'Широкий спектор применения',
            },
            navigation: {
                main: 'Главная',
                about: 'О нас',
                products: 'Продукты',
                news: 'Новости',
                contacts: 'Контакты',
            },
            buttons:{
                additionalInfo: ' Дополнительная информация',
                contactUs: 'Связаться с нами',
                 anotherNews: 'Читать другие новости'
            },
            contactUs:{
                title:'Свяжитесь нами!',
                subtitle: 'И наши сотрудники обязательно с вами свяжутся.',
                name: 'Имя',
                company: 'Компания',
                email: 'Почта',
                city: 'Страна',
                text:'Текст сообщения',
                submit: 'Отправить',
                checkboxText: 'Я согласен получать новости о последних новинках ELECTRONIC AUTONOMOUS SOLUTIONS'
            },
            footer: {
                privacyPolicy: 'Политика конфиденциальности',
                licenseAgreement: 'Лицензионное соглашение',
                frequentlyAskedQuestions: 'Часто задаваемые вопросы',
                contactsUs: 'Свяжитесь с нами',
                  rights: 'все права защищены'
            },
            product:{
                title: 'Наши продукты',
                subtitle: 'Мы рады предоставить вам спектор своих продуктов',
                contentTitle: 'Наша продукция',
                seeAll: 'Смотреть полностью'
            },
            pageAbout:{
                aboutHero: {
                    title: 'О нас',
                    subtitle: ' Мы рады предоставить вам нашу компанию, специализирующуюся в разработке высокоэффективных систем противодействия малоразмерным БПЛА.',
                },
                aboutSecondBlock: {
                    leftText: 'В настоящее время и в целях поставки надежных систем противодействия малоразмерным БПЛА для наших клиентов, мы работаем над усовершенствованием нашей продукции',
                    rightText: 'Мы готовы предоставить вам продукцию, в соответсвии с вашими требованиями и имеем полную возможность модернизировать, перепроектировать, осуществлять техническую поддержку и сервисного обслуживания для уствойчивой работы данной продукции',
                },
                certificates: {
                    title: 'Корпоративные награды и сертификаты',
                    subtitle: 'Компания “Electronic Autonomous Solutions” гордится полученными корпоративными наградами и сертификатами, которые подтверждают наши достижения и высокий уровень качества. Мы были удостоены престижных наград за инновации в области противодронных технологий и успешное внедрение передовых решений.'
                },
                development:{
                    title: 'Наше развитие',
                    one: {
                        textOne: 'Основание компании ООО “Electronic  autonomous solutions”',
                        textTwo: 'Разработка испытательного (прототипного) антидроного устроиства.',
                        textThree: 'Проведение первого практического испытания портативного  антидронового устроиства нашей компании для Министерства обороны, МВД. Государственной  службы безопасности  Президента Республики Узбекистан , Пограничных воиск СГБ, Национальной гвардии и других заинтересованных организаций.',
                    },
                    two: {
                        textOne: 'Получение лицензии  от Агенства оборонной промышленности при Министерстве  обороны Республики Узбекистан на разработку и реализации портативных и стационарных систем противодействий малоразмерным БПЛА.',
                        textTwo: 'Научное исследование и разработка портативных и стационарных систем противодействия малоразмерным БПЛА.',
                        textThree: 'Проведение практического испытания  портативных и стационарных систем противодействия малоразмерным БПЛА для министерства обороны МВД. Государственной службы безопасности Президента Респулики Узбекистан , Пограничных воиск СГБ, Национальной гвардии и других заинтересованных организаций.',
                        textFour: 'Получение международного сертификата ISO 9001:2015 (система менеджмента качества)  в области: производство комплектов  по борьбе  с дронами <<EAS>> (антидрон).',
                        textFive: 'Проведение встреч  с зарубежными  компаниями  о сотрудничестве и совместной  деятельности (с компанией  <<Gurbuz Trade>>  (Турция)  подписание документа <<Соглашение о сотрудничестве  и совместной деятельности >>; с АО <<Западно-Казахстанский машиностроительный завод>> . Республика Казахстан  заключен  двухсторонний меморандум  о <<Сотрудничестве и совместной деятельности>>)'
                    },
                    three: {
                        textOne: 'Включение компании <<EAS >> в Государственный реестр <<Единного поставщика>> Республики Узбекистан',
                        textTwo: 'Научное исследование и разработка новых высокотехнологичных портативных, мобильных и стационарных систем противодействия малоразмерным БПЛА.',
                        textThree: 'Подтверждения международного сертификата ISO 9001 2015 (система менеджмента качества) в области производства комплексов по борьбе с дронами <<EAS>> (фнтидрон)',
                        textFour: 'Заключение контракта на поставку систем противодействия малоразмерным БПЛА для нужд Министерства внутренних дел. Пограничных воиск службы Государственной безопасности и Национальной гвардии Республики Узбекистан',
                        textFive: 'Проведение встреч  с зарубежными  компаниями  о сотрудничестве и совместной  деятельности ( компания  <<Skycope>>  (Канада)  подписание документа <<Соглашение о сотрудничестве  и совместной деятельности >>;  Оборонная промышленность (Завол артиллерийского оборудования) Республики Индии - заключен двусторонний меморандум о <<Сотрудничестве и совместной деятельности>>)'
                    },
                    four: {
                        textOne: 'Развитие компании <<Electronic automous solutions>> до международного уровня.',
                        textTwo: 'Научное исследование и разработка новых высокотехнологичных систем и комплексов (радиоэлектронной борьбы, противовоздушной обороны, беспилотных авиационных комплексов и т.д).',
                        textThree: 'Производства и реализация передовых систем комплексов (радиоэлектронной борьбы, противовоздушной обороны, беспилотных авиационных комплексов, работотехники и т.д) на основе исскусственого интеректа в сфере обороны и безопасности Республики Узбекистан.',
                        textFour: 'Экспорт производвтсва компании <<EAS>> зарубежным странам. Налаживание двестороннего международного сотрудничества и совместной деятельности.',
                    },
                },
                international: {
                    title: 'Международный опыт',
                    subtitle: 'Наш международный опыт включает в себя несколько ключевых моментов. Мы начали активно выходить на зарубежные рынки в 2023 году. Наши противодронные системы были успешео представлены на международных выставках в Европе и Азии, где получили положительные отзывы от провессионалов и клиентов.',
                    one: {
                        first: {
                            title: 'DEX-2023',
                            subtitle: 'DEX-2023 - 16-я Международная выставка оборонной и безопасности промышленности, проходившая с 20 по 24 февраля 2023 года в Абу-Даби (Обьедененные Арабские Эмираты)'
                        },
                        second: {
                           subtitle: 'DEX-2023 - 16-я Международная выставка оборонной и безопасности промышленности, проходившая с 20 по 24 февраля 2023 года в Абу-Даби (Обьедененные Арабские Эмираты)'
                        }
                    },
                    two: {
                        first: {
                            title: 'Inter Drone Expo',
                            subtitle: 'Выставка проходила с 15 по 17 июня 2023 года в Софии, Болгария.'
                        },
                        second: {
                            subtitle: 'Выставка проходила с 15 по 17 июня 2023 года в Софии, Болгария.'
                        }
                    },
                    three: {
                        first: {
                            title: 'IDEF 2023',
                            subtitle: 'Меджународная выставка оборонной промышленности, проходившая с 25 по 28 июля 2023 года в Стамбуле, Турция.'
                        },
                        second: {
                            subtitle: 'Меджународная выставка оборонной промышленности, проходившая с 25 по 28 июля 2023 года в Стамбуле, Турция.'
                        }
                    },
                    four: {
                        first: {
                            title: 'SEDEC 2024',
                            subtitle: 'Международная выставка оборонной безопасности, проходившая с 21 по 23 мая 2024 года в Анкаре, Турция'
                        },
                        second: {
                            subtitle: 'Международная выставка оборонной безопасности, проходившая с 21 по 23 мая 2024 года в Анкаре, Турция'
                        }
                    },
                    five: {
                        first: {
                            title: 'Армия -2023',
                            subtitle: 'Международный военно-технический форум, проходивший с 14 по 20 августа 2023 года в Кубинке, Российская Федерация'
                        },
                        second: {
                            subtitle: 'Международный военно-технический форум, проходивший с 14 по 20 августа 2023 года в Кубинке, Российская Федерация'
                        }
                    },
                    six: {
                        first: {
                            title: 'World Defense Show 2024',
                            subtitle: 'Международная выставка оборонной промышленности, прошла с 4 по 8 февраля 2024 года в Эр-Рияде, Саудовская Аравия'
                        },
                        second: {
                            subtitle: 'Международная выставка оборонной промышленности, прошла с 4 по 8 февраля 2024 года в Эр-Рияде, Саудовская Аравия'
                        }
                    },
                    seven: {
                        first: {
                            title: 'Eurosatory 2024',
                            subtitle: "Международная оборонная выставка, которая прошла с 17 по 21 июня 2024 года в Париже, Франция",
                        },
                        second: {
                            subtitle: "Международная оборонная выставка, которая прошла с 17 по 21 июня 2024 года в Париже, Франция",
                        }
                    }
                }
            }
        },
      },
    },
    uz: {
        translation: {
            blocks: {
                modalWindow: {
                    title: "Tez-tez so'raladigan savollar"
                },
                toaster: {
                    success: 'Xabar yuborildi',
                    error: 'Xabar yuborishda xatolik yuz berdi'
                },
                mainHero:{
                    heroTitle: 'Kelajakni himoya qilish uchun innovatsion echimlar',
                    heroSubtitle: "Biz yuqori texnologiyalarni ishlab chiqish va ishlab chiqarish bo'yicha jahon yetakchilaridan biriga aylanishga intilamiz mudofaa va xavfsizlik sohasidagi mahsulotlar"
                },
                mainAbout: {
                    mainAboutLeft: "Sizga yuqori samarali tizimlarni ishlab chiqishga ixtisoslashgan kompaniyamizni taqdim etishdan mamnunmiz kichik o'lchamli UAVlarga qarshi kurash.",
                    mainAboutRight: "Biz mahalliy foydalanuvchilarning ishonchi va qo'llab-quvvatlashiga erishdikvazirlik va idoralar, shu jumladan O‘zbekiston Respublikasi huquqni muhofaza qilish organlari"
                },
                advantages:{
                    title: 'Bizning afzalliklarimiz',
                    text: "Uchuvchisiz uchish apparatlaridan ishonchli himoyani ta'minlash uchun biz bir qator ilg'or qurilmalarni ishlab chiqdik texnologiyalar. Bizning yechimlarimiz radar sohasidagi so'nggi ishlanmalarni birlashtiradi, elektron tiqilib qolish va sun'iy intellekt.",
                    advantagesOne: 'Yuqori samaradorlik',
                    advantagesTwo: "Ishonchlilik va qo'llab-quvvatlash",
                    advantagesThree: "Moslashuvchanlik va moslashuvchanlik",
                    advantagesFour: "Zamonaviy texnologiyalar",
                    advantagesFive: "Katta tajriba va professionallik",
                    advantagesSix: "Ilovalarning keng doirasi",
                },
                news: {
                    title: 'Yangiliklar',
                    seeMore: "Batafsil ma'lumot"
                },
                navigation: {
                    main: 'Uy',
                    about: 'Biz haqimizda',
                    products: 'Mahsulotlar',
                    news: 'Yangiliklar',
                    contacts: 'Kontaktlar',
                },
                buttons:{
                    additionalInfo: "Qo'shimcha ma'lumot",
                    contactUs: "Biz bilan bog'lanish",
                    anotherNews: "Ko'proq yangiliklarni o'qing"
                },
                product:{
                    title: 'Bizning mahsulotlarimiz',
                    subtitle: 'Sizga mahsulotlarimiz qatorini taqdim etishdan mamnunmiz.',
                    contentTitle: 'Bizning mahsulotlarimiz',
                    seeAll: 'Toʻliq koʻrish'
                },
                contactUs:{
                    title: "Biz bilan bog'lanish!",
                    subtitle: "Va bizning xodimlarimiz siz bilan albatta bog'lanadi.",
                    name: 'Ism',
                    company: 'Kompaniya',
                    email: 'Pochta',
                    city: 'Mamlakat',
                    text:'Xabar',
                    submit: 'Yuborish',
                    checkboxText: "ELEKTRON AVTONOM SOLUTIONS kompaniyasining so'nggi yangiliklarini olishga roziman"
                },
                footer: {
                    privacyPolicy: 'Maxfiylik siyosati',
                    licenseAgreement: 'Litsenziya shartnomasi',
                    frequentlyAskedQuestions: "Tez-tez so'raladigan savollar",
                    contactsUs: "Biz bilan bog'lanish",
                    rights: 'barcha huquqlar himoyalangan'
                },
                pageAbout:{
                    aboutHero: {
                        title: 'Biz haqimizda',
                        subtitle: "Sizga yuqori samarali tizimlarni ishlab chiqishga ixtisoslashgan kompaniyamizni taqdim etishdan mamnunmiz kichik o'lchamli UAVlarga qarshi kurash.",
                    },
                    aboutSecondBlock: {
                        leftText: "Hozirda va bizning kichik o'lchamli uchuvchisiz uchuvchisiz uchoqlarga qarshi ishonchli tizimlarni yetkazib berish maqsadida mijozlar, biz mahsulotlarimizni yaxshilash ustida ishlayapmiz",
                        rightText: "Biz sizning talablaringizga mos mahsulotlar bilan ta'minlashga tayyormiz va barcha imkoniyatlarga egamiz modernizatsiya qilish, qayta loyihalash, texnik yordam va xizmat ko'rsatish ushbu mahsulotning barqaror ishlashi",
                    },
                    certificates: {
                        title: 'Korporativ mukofotlar va sertifikatlar',
                        subtitle: "Electronic Autonomous Solutions kompaniyasi olgan korporativ mukofotlari bilan faxrlanadi va yutuqlarimiz va yuqori sifat darajasini tasdiqlovchi sertifikatlar. Biz hurmatga sazovor bo'ldik uchuvchisiz qurilmalarga qarshi texnologiyalar sohasidagi innovatsiyalar va ilg'or texnologiyalarni muvaffaqiyatli amalga oshirish uchun nufuzli mukofotlar qarorlar."
                    },
                    international: {
                        title: 'Xalqaro tajriba',
                        subtitle: "Bizning xalqaro tajribamiz bir qancha muhim jihatlarni o‘z ichiga oladi. Biz 2023 yildan boshlab tashqi bozorlarga faol chiqa boshladik. Bizning dronga qarshi tizimlarimiz Evropa va Osiyodagi xalqaro ko'rgazmalarda muvaffaqiyatli namoyish etildi va u erda ular professionallar va mijozlardan ijobiy fikrlarni oldilar.",
                        one: {
                            first: {
                                title: 'DEX-2023',
                                subtitle: 'DEX-2023 - 2023-yil 20-24-fevral kunlari Abu-Dabida (Birlashgan Arab Amirliklari) boʻlib oʻtgan 16-Xalqaro mudofaa va xavfsizlik sanoati koʻrgazmasi'
                            },
                            second: {
                                subtitle: 'DEX-2023 - 2023-yil 20-24-fevral kunlari Abu-Dabida (Birlashgan Arab Amirliklari) boʻlib oʻtgan 16-Xalqaro mudofaa va xavfsizlik sanoati koʻrgazmasi'
                            }
                        },
                        two: {
                            first: {
                                title: 'Inter Drone Expo',
                                subtitle: "Ko'rgazma 2023 yil 15 iyundan 17 iyungacha Bolgariyaning Sofiya shahrida bo'lib o'tdi.",
                            },
                            second: {
                                subtitle: "Ko'rgazma 2023 yil 15 iyundan 17 iyungacha Bolgariyaning Sofiya shahrida bo'lib o'tdi.",
                            }
                        },
                        three: {
                            first: {
                                title: 'IDEF 2023',
                                subtitle: "2023-yil 25-28-iyul kunlari Turkiyaning Istanbul shahrida boʻlib oʻtgan Xalqaro mudofaa sanoati koʻrgazmasi.",
                            },
                            second: {
                                subtitle: "2023-yil 25-28-iyul kunlari Turkiyaning Istanbul shahrida boʻlib oʻtgan Xalqaro mudofaa sanoati koʻrgazmasi.",
                            }
                        },
                        four: {
                            first: {
                                title: 'SEDEC 2024',
                                subtitle: "2024-yil 21-23-may kunlari Turkiyaning Ankara shahrida Xalqaro mudofaa xavfsizligi koʻrgazmasi boʻlib oʻtdi",
                            },
                            second: {
                                subtitle: "2024-yil 21-23-may kunlari Turkiyaning Ankara shahrida Xalqaro mudofaa xavfsizligi koʻrgazmasi boʻlib oʻtdi",
                            }
                        },
                        five: {
                            first: {
                                title: 'Армия -2023',
                                subtitle: '2023-yil 14-20-avgust kunlari Rossiya Federatsiyasining Kubinka shahrida boʻlib oʻtgan Xalqaro harbiy-texnik forum'
                            },
                            second: {
                                subtitle: '2023-yil 14-20-avgust kunlari Rossiya Federatsiyasining Kubinka shahrida boʻlib oʻtgan Xalqaro harbiy-texnik forum'
                            }
                        },
                        six: {
                            first: {
                                title: 'World Defense Show 2024',
                                subtitle: "2024-yil 4-8-fevral kunlari Saudiya Arabistoni Ar-Riyod shahrida boʻlib oʻtgan Xalqaro mudofaa sanoati koʻrgazmas 2024 yil 17-21 iyun kunlari Parijda (Fransiya) boʻlib oʻtgan Xalqaro mudofaa koʻrgazmasi",
                            },
                            second: {
                                subtitle: "2024-yil 4-8-fevral kunlari Saudiya Arabistoni Ar-Riyod shahrida boʻlib oʻtgan Xalqaro mudofaa sanoati koʻrgazmas 024 yil 17-21 iyun kunlari Parijda (Fransiya) boʻlib oʻtgan Xalqaro mudofaa koʻrgazmasi",
                            }
                        },
                        seven: {
                            first: {
                                title: 'Eurosatory 2024',
                                subtitle: "2024 yil 17-21 iyun kunlari Parijda (Fransiya) boʻlib oʻtgan Xalqaro mudofaa koʻrgazmasi",
                            },
                            second: {
                                subtitle: "2024 yil 17-21 iyun kunlari Parijda (Fransiya) boʻlib oʻtgan Xalqaro mudofaa koʻrgazmasi",
                            }
                        }
                    },
                    development:{
                        title: 'Bizning taraqqiyotimiz',
                        one: {
                            textOne: '“Elektron avtonom yechimlar” MChJ kompaniyasining tashkil etilishi',
                            textTwo: "Dronga qarshi sinov (prototip) qurilmasini ishlab chiqish.",
                            textThree: "Mudofaa, Ichki ishlar vazirliklari uchun kompaniyamizning ko‘chma uchuvchisiz uchuvchisiz qurolini birinchi amaliy sinovdan o‘tkazish. O‘zbekiston Respublikasi Prezidentining Davlat xavfsizlik xizmati, Davlat xavfsizlik xizmatining Chegara qo‘shinlari, Milliy gvardiya va boshqa manfaatdor tashkilotlar.",
                        },
                        two: {
                            textOne: "Oʻzbekiston Respublikasi Mudofaa vazirligi huzuridagi Mudofaa sanoati agentligidan kichik oʻlchamli uchuvchisiz uchuvchisiz uchish qurilmalariga qarshi turishning koʻchma va statsionar tizimlarini ishlab chiqish va joriy etish uchun litsenziya olish.",
                            textTwo: "Ilmiy tadqiqotlar va kichik UAVlarga qarshi ko'chma va statsionar tizimlarni ishlab chiqish.",
                            textThree: "Ichki ishlar vazirligi Mudofaa vazirligi uchun kichik oʻlchamli uchuvchisiz uchuvchisiz uchish apparatlariga qarshi turish uchun portativ va statsionar tizimlarni amaliy sinovdan oʻtkazish. O‘zbekiston Respublikasi Prezidentining Davlat xavfsizlik xizmati, Davlat xavfsizlik xizmatining Chegara qo‘shinlari, Milliy gvardiya va boshqa manfaatdor tashkilotlar.",
                            textFour: "Sohada ISO 9001:2015 (sifat menejmenti tizimi) xalqaro sertifikatini olish: dronga qarshi to'plamlar <<EAS>> (dronga qarshi) ishlab chiqarish.",
                            textFive: "Xorijiy kompaniyalar bilan hamkorlik va qoʻshma faoliyat masalalari boʻyicha uchrashuvlar oʻtkazish (<<Gurbuz Trade>> kompaniyasi (Turkiya) bilan “Hamkorlik va qoʻshma faoliyat toʻgʻrisida bitim” hujjati imzolandi; “Gʻarbiy Qozogʻiston mashinasozlik zavodi” AJ bilan. Respublika. Qozogʻiston oʻrtasida <<Hamkorlik va qoʻshma faoliyat>> toʻgʻrisida ikki tomonlama memorandum tuzildi."
                        },
                        three: {
                            textOne: "<<EAS>> kompaniyasini O'zbekiston Respublikasi <<Yagona yetkazib beruvchi>> davlat reestriga kiritish.",
                            textTwo: "Ilmiy tadqiqotlar va kichik o'lchamli UAVlarga qarshi kurashish uchun yangi yuqori texnologiyali ko'chma, mobil va statsionar tizimlarni ishlab chiqish.",
                            textThree: "Dronga qarshi komplekslarni ishlab chiqarish sohasida ISO 9001 2015 (sifat menejmenti tizimi) xalqaro sertifikatini tasdiqlash <<EAS>> (ftidron)",
                            textFour: "Ichki ishlar vazirligi ehtiyojlari uchun kichik UAVlarga qarshi tizimlarni yetkazib berish bo'yicha shartnoma tuzish. O‘zbekiston Respublikasi Davlat xavfsizlik xizmati va Milliy gvardiyaning chegara qo‘shinlari",
                            textFive: "Xorijiy kompaniyalar bilan hamkorlik va qoʻshma faoliyat boʻyicha uchrashuvlar oʻtkazish (<<Skycope>> kompaniyasi (Kanada) “Hamkorlik va qoʻshma faoliyat toʻgʻrisida shartnoma” hujjatini imzolash; Hindiston Respublikasining mudofaa sanoati (artilleriya uskunalari zavodi) - ikki tomonlama memorandum. “Hamkorlik” nihoyasiga yetdi va qo‘shma tadbirlar o‘tkazildi>>)"
                        },
                        four: {
                            textOne: "<<Elektron avtomatik echimlar>> kompaniyasining xalqaro darajaga rivojlanishi.",
                            textTwo: "Ilmiy tadqiqotlar va yangi yuqori texnologiyali tizimlar va komplekslarni (elektron urush, havo mudofaasi, uchuvchisiz havo tizimlari va boshqalar) ishlab chiqish.",
                            textThree: "O‘zbekiston Respublikasi mudofaasi va xavfsizligi sohasida sun’iy intellektga asoslangan ilg‘or kompleks tizimlarni (elektron urush, havo hujumidan mudofaa, uchuvchisiz uchish tizimlari, ish jihozlari va boshqalar) ishlab chiqarish va sotish.",
                            textFour: "<<EAS>> kompaniyasi mahsulotini xorijiy davlatlarga eksport qilish. Ikki tomonlama xalqaro hamkorlikni yo'lga qo'yish va birgalikdagi faoliyatni amalga oshirish.",
                        },
                    }
                }
            },
        }
    }
  };